<template>
  <section class="flex flex-col gap-s24">
    <div class="flex flex-col items-center gap-s30">
      <img :src="currentIcon" class="gem-image" />
      <div class="flex flex-col items-center gap-s8">
        <BaseText
          data-cy="gems-count"
          data-testid="gems-count"
          size="headline-x-large">
          {{ gems }}
        </BaseText>
        <BaseText size="body-text-x-medium">{{ subDescription }}</BaseText>
      </div>
    </div>
    <BaseText size="subheadline-large">{{ subTitle }}</BaseText>
    <BaseText size="body-text-large">{{ description }}</BaseText>
  </section>

</template>

<script>
import { BaseText } from '@/components/misc';

export default {
  name: 'RewardGemsEarnedLayout',
  components: {
    BaseText
  },
  props: {
    gems: {
      type: String,
      required: true
    },
    subDescription: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
  },
  computed: {
    currentIcon() {
      const file = `gem-${[this.currentTheme || 'light']}.png`;
      return require(`@/assets/icons/${file}`);
    },
  }
};
</script>

<style scoped>
  .gem-image {
    aspect-ratio: 113/139;
    max-height: 113px;
  }
</style>
